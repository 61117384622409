import React from 'react';
import './App.css';
import axios from 'axios';
import key from './key.json';

import FolderIcon from '@mui/icons-material/Folder';
import ArticleIcon from '@mui/icons-material/Article';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      idFolders: [],
      DataisLoaded: false,
    };
  }

  getAccessToken = () => {
    axios
      .post('https://oauth2.googleapis.com/token', {
        'client_id': key.client_id,
        'client_secret': key.client_secret,
        'refresh_token': key.refresh_token,
        'grant_type': 'refresh_token',
      })
      .then((data) => {
        key.access_token = data.data.access_token;
      })
      .catch(function (error) {
        console.log(error);
      })
    return key.access_token;
  }

  componentDidMount() {
    var access_token = this.getAccessToken();
    var header = {
      headers: {
        'Authorization': `Bearer ${access_token}`,
      }
    };

    axios
      .get(`https://www.googleapis.com/drive/v3/files?q='${key.folder_id}'+in+parents&pageSize=1000&fields=nextPageToken,files(id,name,mimeType,size,createdTime,modifiedTime,parents)`, header)
      .then((data) => {
        let transformedData = data.data.files.map(d => {
          return {
            id: d.id,
            parents: d.parents[0],
            name: d.name,
            mimeType: d.mimeType,
            size: (d.size === undefined ? null : d.size),
            createdTime: d.createdTime,
            modifiedTime: d.modifiedTime,
          }
        })
        this.setState({
          data: transformedData,
          idFolders: [key.folder_id],
          DataisLoaded: true,
        });
      })
  }

  getData = (folderid, k) => {
    if (folderid === null || folderid === undefined || folderid === '') {
    }
    else {
      var access_token = this.getAccessToken();
      var header = {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        }
      };

      axios
        .get(`https://www.googleapis.com/drive/v3/files?q='${folderid}'+in+parents&pageSize=1000&fields=nextPageToken,files(id,name,mimeType,size,createdTime,modifiedTime,parents)`, header)
        .then((data) => {
          let transformedData = data.data.files.map(d => {
            return {
              id: d.id,
              parents: d.parents[0],
              name: d.name,
              mimeType: d.mimeType,
              size: (d.size === undefined ? null : d.size),
              createdTime: d.createdTime,
              modifiedTime: d.modifiedTime,
            }
          })
          this.setState({
            data: transformedData,
            // k = 0 go to folder, k = 1 go back to parent folder
            idFolders: k === 0 ? [...this.state.idFolders, folderid] : this.state.idFolders.slice(0, this.state.idFolders.length - 1),
            DataisLoaded: true,
          });
        })
    }
  }

  openFile = (fileID) => {
    window.open(`https://drive.google.com/file/d/${fileID}/view`, '_blank');
  }

  render() {
    {
      if (!this.state.DataisLoaded) { return <div>Loading...</div> }
      else
        return (
          <div className="container">
            <h1>Haizzzz</h1>
            <div className="card-body">
              
              <div className="tb">
                <div className='item2'>
                  <a className='icon' style={{ color: '#000' }}>Type</a>
                  <a className='name'>Name</a>
                </div>
                {this.state.data.map((d, index) => {
                  return (
                    d.id === key.folder_id ? null : <button className="btn btn-back" onClick={() => this.getData(this.state.idFolders[this.state.idFolders.length - 2], 1)}>....</button>,
                    <div className='item'>
                      <div className='icon'>
                        {d.mimeType === 'application/vnd.google-apps.folder' ?
                          <div className='folder' style={{ textAlign: 'center' }} ><FolderIcon /></div> :
                          <div className='file' style={{ textAlign: 'center' }}><ArticleIcon /></div>}
                      </div>
                      <div className='name' style={{ width: '800px' }} >
                        {d.mimeType === 'application/vnd.google-apps.folder' ?
                          <button className="btn btn-item" onClick={() => this.getData(d.id, 0)}>
                            <a>{d.name}</a>
                          </button> :
                          <button className="btn btn-item" onClick={() => this.openFile(d.id)}>
                            <a>{d.name}</a>
                          </button>}
                      </div>
                    </div>
                  )
                })}
              </div>
              {/* <table className="tb">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((d, index) => {
                    return (
                      <div className='item'>
                        <tr key={index}>
                          {d.mimeType === 'application/vnd.google-apps.folder' ?
                            <td className='folder' style={{ textAlign: 'center' }} ><FolderIcon /></td> :
                            <td className='file' style={{ textAlign: 'center' }}><ArticleIcon /></td>}
                          <td style={{ width: '800px' }} >
                            {d.mimeType === 'application/vnd.google-apps.folder' ?
                              <a rel="noopener noreferrer" onClick={() => this.getData(d.id, 0)}>{d.name}</a> :
                              <a rel="noopener noreferrer" href={`https://drive.google.com/file/d/${d.id}/view`} target="_blank">{d.name}</a>}
                          </td>
                        </tr>
                      </div>
                    )
                  })}
                </tbody>
              </table> */}
            </div>
          </div >
        )
    }
  }
}

export default App;